<template>
  <div>
    <steps ref="steps"></steps>
    <div class="border">
      <div class="flex Application">
        <div style="margin-right: 5px">申请状态:{{ statusTitle }}</div>
        <i class="el-icon-refresh icons" @click="RefresshClick"></i>
      </div>
      <div class="content">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <div class="flexs">
            <el-form-item
              label="身份证号"
              prop="credentialNumber"
              label-width="160px"
            >
              <el-input v-model="ruleForm.credentialNumber"></el-input>
            </el-form-item>
            <el-form-item label="绑定银行" prop="bankID" label-width="160px">
              <!-- <el-input v-model="ruleForm.bankID"></el-input> -->
              <el-select
                v-model="ruleForm.bankID"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.bankId"
                  :label="item.bankName"
                  :value="item.bankId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="flexs">
            <el-form-item
              label="银行账户名称"
              prop="bankAccountName"
              label-width="160px"
            >
              <el-input v-model="ruleForm.bankAccountName"></el-input>
            </el-form-item>
            <el-form-item
              label="银行账户号码"
              prop="bankAccountNumber"
              label-width="160px"
            >
              <el-input v-model="ruleForm.bankAccountNumber"></el-input>
            </el-form-item>
          </div>
          <div class="flexs">
            <el-form-item
              label="银行卡预留手机号码"
              prop="bankPhoneNumber"
              label-width="160px"
            >
              <el-input v-model="ruleForm.bankPhoneNumber" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item label="" label-width="160px"> </el-form-item>
          </div>

          <el-form-item>
            <el-button
              type="primary"
              style="margin-top: 30px"
              @click="submitForm('ruleForm')"
              >提交绑卡</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/action-types";
import Vue from "vue";
import {
  listBankCode,
  bindCardForZJ,
  getAccountStatusForZJ,
} from "@/api/Accountopening";
export default {
  data() {
    return {
      statusTitle: "未申请",
      options: [],
      ruleForm: {
        credentialNumber: "",
        bankID: "",
        bankAccountName: "",
        bankAccountNumber: "",
        bankPhoneNumber: "",
        ifOpenAccount: "1",
      },
      rules: {
        credentialNumber: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {
            pattern:
              /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/,
            message: "请输入有效法人身份证号",
            trigger: "blur",
          },
        ],
        bankID: [
          { required: true, message: "请选择绑定银行", trigger: "change" },
        ],
        bankAccountName: [
          {
            required: true,
            message: "请输入银行账户名称(企业同名账户)",
            trigger: "blur",
          },
        ],
        bankAccountNumber: [
          { required: true, message: "请输入银行账户号码", trigger: "blur" },
        ],
        bankPhoneNumber: [
          {
            required: true,
            message: "请输入银行卡预留手机号码",
            trigger: "blur",
          },
          {
            type: "number",
            message: "手机号格式不正确",
            trigger: "blur",
            transform(value) {
              var phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
              if (!phonereg.test(value)) {
                return false;
              } else {
                return Number(value);
              }
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.getlistBankCode();
    this.getStatusForZJ();
  },
  methods: {
    getStatusForZJ() {
      getAccountStatusForZJ().then((res) => {
        if (res.data.bindCardStatus == 10) {
          this.statusTitle = "处理中";
        }
        if (res.data.bindCardStatus == 17) {
          this.statusTitle = "待被动打款验证";
        }
        if (res.data.bindCardStatus == 18) {
          this.statusTitle = "被动已打款待验证";
          Vue.ls.set(types.active, 2);
          this.$emit("logoutHeader2", 2);
          this.$refs.steps.getactive();
        }
        if (res.data.bindCardStatus == 40) {
          this.statusTitle = "绑卡失败";
        }
      });
    },
    RefresshClick() {
      this.getStatusForZJ();
      this.$message({
        message: '刷新成功',
        type: "success",
      });
    },
    getlistBankCode() {
      let config = {
        pageNum: 1,
        pageSize: 999,
      };
      listBankCode(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.options = res.data.list;
        }
      });
    },
    submitForm(val) {
      this.$refs[val].validate((valid) => {
        if (valid) {
          let config = this.ruleForm;
          bindCardForZJ(config).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.getStatusForZJ();
            } else {
              this.$message.error(res.message);
            }
          });
          console.log(config);
        }
      });
    },
  },
  components: {
    steps: () => import("./steps.vue"),
  },
};
</script>

<style lang="scss" scoped>
.border {
  border: 1px solid black;
  width: 100%;
  padding: 20px;
}
.Application {
  justify-content: right;
  margin-bottom: 5px;
}
.flex {
  display: flex;
  align-items: center;
}
.icons {
  cursor: pointer;
}
.flexs {
  display: flex;
  margin-left: 150px;
  // justify-content: space-between;
}
.content {
  margin: 0 auto;
  text-align: center;
}
</style>